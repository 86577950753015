@use 'css/abstracts' as *;
.sct-case {
	position: relative;
	overflow: hidden;

	.sct_inner {
		position: relative;
		padding: 86px 24px 0;
		@media (min-width: 769px) {
			// padding: 64px 80px 0;
			padding: 120px 80px 0;
		}
	}
	.sec-description {
		text-align: center;
		font-weight: 400;
		font-size: 16px;
		line-height: 180%;
		margin-top: 40px;
		color: #fff;
		@media (min-width: 769px) {
		}
	}
	.note {
		font-family: 'YuGothic';
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 180%;
		text-align: right;
		color: #ffffff;
		max-width: 1008px;
		margin: auto;
		margin-top: 16px;
		@media (min-width: 769px) {
			margin-top: -24px;
		}
	}
}
.o-case_cards {
	max-width: 1008px;
	margin: auto;
	margin-top: 40px;
	position: relative;
	@media (min-width: 769px) {
		margin-top: 72px;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}
}
.m-case_card {
	padding: 8px;
	background: #f1f1f1;
	& + .m-case_card {
		margin-top: 40px;
	}
	@media (min-width: 769px) {
		flex: 1 1 calc(50% - 24px);
		margin-bottom: 40px;
		&:nth-child(even) {
			margin-left: 48px;
		}
		& + .m-case_card {
			margin-top: 0;
		}
	}
}
.case_card_inner {
	text-align: center;
	border: 1px solid #111111;
	padding: 32px 15px 15px;
	height: 100%;
	h3 {
		position: relative;
		display: block;
		padding: 8px 10px 4px 9px;
		font-weight: 400;
		font-size: 20px;
		line-height: 150%;
		text-align: center;
		color: #ffffff;
		background: #0b2945;
		@media (min-width: 769px) {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			padding-left: 8px;
			padding-top: 5px;
			padding-bottom: 5px;
			padding-right: 16px;
		}
	}
	.tag {
		position: absolute;
		top: -17px;
		left: -8px;
		display: inline-block;
		padding: 3px 8px;
		font-weight: 500;
		font-size: 12px;
		line-height: 150%;
		color: #0b2945;
		background: linear-gradient(
			93.28deg,
			#ae9553 1.75%,
			#cfba83 23.2%,
			#b99b4b 99.83%
		);
		@media (min-width: 769px) {
			position: relative;
			top: auto;
			left: auto;
			height: 24px;
			margin-right: 8px;
		}
	}
	.heading {
		@media (min-width: 769px) {
			text-align: center;
			flex: auto;
		}
	}
	.incom {
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: flex-end;
	}
	.incom_text {
		display: inline-block;
		font-weight: 700;
		font-size: 20px;
		line-height: 150%;
		color: #7a7159;
		padding-bottom: 15px;
	}
	.incom_num {
		font-weight: 700;
		font-size: 52px;
		line-height: 150%;
		margin-left: 4px;
		margin-right: 3px;
	}
}

.case_card_flex {
	@media (min-width: 769px) {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		max-width: 400px;
		margin: auto;
		.case_card_text,
		.case_card_tag {
			margin: 0 8px;
		}
	}
}

.case_card_text {
	font-weight: 400;
	font-size: 18px;
	line-height: 150%;
	text-align: center;
	color: #0b2945;
	margin-top: 8px;
}
.case_card_tag {
	display: inline-block;
	border: 1px solid #0b2945;
	font-weight: 400;
	font-size: 16px;
	line-height: 150%;
	text-align: center;
	color: #0b2945;
	padding: 0 12px;
	// & + .case_card_text{
	// 	@media (min-width: 769px) {
	// 		display: inline-block;
	// 		margin-top: 0;
	// 		margin-left: 16px;
	// 	}
	// }
}
