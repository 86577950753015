@use 'css/abstracts' as *;
.sct-merit {
	position: relative;
	overflow: hidden;

	.sct_inner {
		padding: 80px 24px ;
		@media (min-width: 769px) {
			padding-top: 120px;
			padding-bottom: 160px;
			padding-left: 0;
			padding-right: 0;
		}
	}
}
.m-merit_content {
	margin-top: 40px;
	& + .m-merit_content {
		margin-top: 56px;
	}
	@media (min-width: 769px) {
		display: flex;
		justify-content: flex-end;
		align-items: flex-start;
		flex-flow: row-reverse;
		// flex-wrap: wrap;
		max-width: 1440px;
		margin: 0 auto;
		margin-top: 80px;
		&.-reverse{
			flex-flow: row;
			.textbox {
				padding-left: 120px;
				padding-right: 80px;
			}
		}
	& + .m-merit_content {
		margin-top: 80px;
	}
	}
	.sub {
		// display: inline-flex;
		// align-items: center;
		font-family: 'Montserrat';
		font-style: normal;
		color: #7a7159;
		.font-s {
			display: inline-block;
			font-size: 24px;
			font-weight: 400;
			line-height: 150%;
			margin-right: 12px;
			padding-bottom: 10px;
		}
		.num {
			display: inline-block;
			font-size: 40px;
			font-weight: 400;
			line-height: 150%;
		}
	}
	.line {
		display: block;
		width: 140px;
		height: 1px;
		background: #7a7159;
	}
	h3 {
		display: inline-block;
		font-weight: 700;
		font-size: 24px;
		line-height: 150%;
		margin-top: 32px;
	}
	.text {
		font-family: YuGothic, 'Yu Gothic', sans-serif;
		font-weight: 500;
		font-size: 14px;
		line-height: 180%;
		text-align: left;
		margin-top: 24px;
	}
	.textbox {
		padding-bottom: 40px;
		@media (min-width: 769px) {
			box-sizing: content-box;
			 width: calc(560/1440*100%);
			 padding-top: 80px;
			 padding-left: 80px;
			 padding-right: 120px;			
		}
	}
	.imgbox {
		display: block;
		position: relative;
		@media (max-width: 767px) {
			left: calc(50% + 24px);
			margin-left: -24px;
			margin-right: -24px;
			transform: translateX(-50%);
		}
		@media (min-width: 769px) {
			    width: calc(640/1440*100%);			
				height: 426px;
				img{
					display: block;
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
		}
	}
}
