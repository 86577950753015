@use 'css/abstracts' as *;
.sct-job_information {
	position: relative;
	overflow: hidden;
	background: #f1f1f1;

	.sct_inner {
		padding: 80px 24px 0;
		@media (min-width: 769px) {
			// padding: 64px 80px 0;
			padding: 120px 80px 0;
		}
	}
	.sec-description {
		text-align: left;
		font-weight: 400;
		font-size: 16px;
		line-height: 180%;
		margin-top: 40px;
		@media (min-width: 769px) {
			text-align: center;
		}
	}
	.m-cv_area {
	padding-top: 252px;
	margin-top: -217px;
	@media (min-width: 769px) {
		margin-top: -230px;
		padding-top: 178px;
	}
	}
}

.o-job_information_lists {
	position: relative;
	margin-top: 40px;
	left: calc(50% + 24px);
	margin-left: -24px;
	margin-right: -24px;
	transform: translateX(-50%);
	.swiper-wrapper {
		margin: auto;
		// max-width: 296px;
	}
	.note {
		font-family: 'YuGothic';
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 180%;
		text-align: right;
		color: #ffffff;
		margin-top: 16px;
		padding-right: 24px;

	}
	@media (min-width: 769px) {
		margin: auto;
		margin-top: 80px;
		// display: flex;
		max-width: 1280px;
		left: 0;
		transform: none;

		.note {
			margin-top: 18px;
			padding-right: 0;
		}
	}
}
.m-job_information_list {
	.slide {
		max-width: 296px;
		height: 100%;
		margin: auto;
		box-shadow: 2px 2px 16px rgba(164, 169, 180, 0.6);
	}
	@media (min-width: 769px) {
		flex: 1 1 calc(25% - 32px * 3 / 4);
		& + .m-job_information_list {
			margin-left: 32px;
		}
	}
}
.list_inner {
	background: #fff;
}
.job_name {
	display: block;
	padding: 10px;
	font-weight: 700;
	font-size: 18px;
	line-height: 140%;
	text-align: center;
	color: #ffffff;
	background: linear-gradient(
		93.92deg,
		#786432 -12.67%,
		#9a8449 27.55%,
		#88784e 106.2%
	);
}
.job_text {
	padding: 24px;
	h4 {
		display: block;
		font-weight: 700;
		font-size: 14px;
		line-height: 180%;
		color: #7a7159;
		margin-top: 12px;
		text-align: left;
	}
	p {
		font-family: YuGothic,'Yu Gothic',sans-serif;
		font-weight: 500;
		font-size: 14px;
		line-height: 180%;
		margin-top: 8px;
		text-align: left;
	}
}
.incom_tag {
	display: inline-block;
	font-weight: 700;
	font-size: 18px;
	line-height: 100%;
	text-align: center;
	color: #ffffff;
	padding: 4px 12px;
	background: #7a7159;
	margin-top: 16px;
}
.incom_num {
	font-weight: 700;
	font-size: 30px;
	line-height: 150%;
	.-font-s {
		font-size: 18px;
	}
}
