@use 'css/abstracts' as *;
.sct-intro {
	position: relative;
	overflow: hidden;
	background: #dfddd6;

	.sct_inner {
		padding: 56px 24px 80px;
		@media (min-width: 769px) {
			padding-top: 64px;
			padding-bottom: 64px;
		}
	}
}
.sct-intro_deco {
	position: absolute;
	width: 403.25px;
	height: 216.85px;
	left: -107.32px;
	top: 0;
	background: radial-gradient(
		50% 50% at 50% 50%,
		#ffffff 0%,
		rgba(255, 255, 255, 0) 100%
	);
	mix-blend-mode: overlay;
	transform: matrix(0.79, 0.61, -0.49, 0.87, 0, 0);
	z-index: 1;
	@media (min-width: 769px) {
		width: 907.3px;
		height: 487.92px;
		left: calc(50% - 907.3px / 2 - 250.75px);
	}
}
.o-intro_wrap {
	position: relative;
	z-index: 10;
	background: #efeeea;
	padding: 8px;
	box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.19);
	max-width: 1120px;
	margin: auto;
	@media (min-width: 769px) {
		padding: 12px;
	}
}
.o-intro_inner {
	background: #ffffff;
	padding: 48px 8px;
	box-sizing: border-box;
	border: 1px solid #000;
	border-image: var(--global-gradation_gold);
	border-image-slice: 1;
	@media (min-width: 769px) {
		padding: 48px 16px;
	}
	& > * {
		display: block;
	}
	h2 {
		font-weight: 600;
		font-size: 20px;
		line-height: 140%;
		text-align: center;
		letter-spacing: 0.05em;
		@media (min-width: 769px) {
			font-size: 24px;
			line-height: 140%;
		}
	}
	.a-Logo {
		width: 130px;
		margin: auto;
		margin-top: 16px;
		margin-bottom: 24px;
		@media (min-width: 769px) {
			width: 140px;
			margin-bottom: 16px;
		}
	}
	p {
		max-width: 700px;
		font-weight: 400;
		font-size: 15px;
		line-height: 200%;
		text-indent: -1em;
		padding-left: 1em;
		margin: auto;
		font-size: 20px;
		font-weight: 700;
		color: #7A7159;
		@media (min-width: 769px) {
			font-size: 20px;
			font-weight: 700;
			color: #7A7159;
		}
	}
}
