@use 'css/abstracts' as *;
.sct-data {
	position: relative;
	overflow: hidden;

	.sct_inner {
		position: relative;
		padding: 80px 24px;
		@media (min-width: 769px) {
			padding-left: 80px;
			padding-right: 80px;
			padding-top: 120px;
			padding-bottom: 120px;
		}
	}
}
.o-data_list_wrap {
	position: relative;
	margin-top: 56px;
	max-width: 1200px;
	@media (min-width: 769px) {
		margin-top: 80px;
		display: flex;
		justify-content: center;
		left: calc(50% + 40px);
		margin-left: -40px;
		margin-right: -40px;
		transform: translateX(-50%);
	}
}
.m-data_list {
    & + .m-data_list{
        margin-top: 80px;
    }
	h3 {
		display: block;
		background: #0060b8;
		font-weight: 600;
		font-size: 20px;
		line-height: 150%;
		text-align: center;
		color: #ffffff;
        max-width: 168px;
        margin: auto;
		padding-top: 2px;
		padding-bottom: 4px;
	}
	picture {
        display: block;
		max-width: 272px;
		margin: auto;
		margin-top: 24px;
	}
	p {
		font-weight: 400;
		font-size: 16px;
		line-height: 150%;
		text-align: center;
		color: #ffffff;
		margin-top: 24px;
	}
	@media (min-width: 769px) {
        flex: 1 1 calc(33.333% - 40px);
        margin: 0 40px;
         & + .m-data_list{
             margin-top: 0;
        }
	}
}
