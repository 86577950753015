@charset "UTF-8";
:root {
  --global-form-color-text: var(--global-color-text);
  --global-form-color-text-contract: var(--global-color-gray-200);
  --global-form-color-placeholder: var(--global-color-gray-400);
  --global-form-color-warn: var(--global-color-warn);
  --global-form-color-warn-contract: var(--global-color-warn-contract);
  --global-form-color-active: var(--global-color-warn);
  --global-form-color-active-contract: var(--global-color-warn-contract);
  --global-form-typofgraphy-fontSize-xs: 0.625rem;
  --global-form-typofgraphy-fontSize-sm: 0.75rem;
  --global-form-typofgraphy-fontSize: var(--global-typography-fontSize-default);
  --global-form-typofgraphy-lineHeight: 1.5;
  --global-form-typofgraphy-lineHeight-lg: 1.8;
  --global-form-typofgraphy-lineHeight-xl: 2;
  --global-form-container-inner-gutter: 16px;
  --global-form-element-min-height: 42px;
  --global-form-element-input-gap-x: 16px;
  --global-form-element-input-gap-x-lg: 12px;
  --global-form-element-input-gap-x-xl: 16px;
  --global-form-element-input-gap-x-2xl: 20px;
  --global-form-element-input-gap-x-3xl: 24px;
  --global-form-element-input-gap-y: 16px;
  --global-form-element-input-gap-y-lg: 12px;
  --global-form-element-input-gap-y-xl: 16px;
  --global-form-element-input-gap-y-2xl: 20px;
  --global-form-element-input-gap-y-3xl: 24px;
  --global-form-element-input-gap-y-4xl: 28px;
  --global-form-element-input-gap-y-5xl: 32px;
  --global-form-state-readonly-opacity: .5;
  --global-form-state-disabled-opacity: .5;
}

/* スライダー非活性時 */
.swiper:not(.swiper-initialized) {
  padding: 0;
}
.swiper:not(.swiper-initialized) ~ .swiper-button-prev, .swiper:not(.swiper-initialized) ~ .swiper-button-next {
  display: none;
}
.swiper:not(.swiper-initialized) .swiper-wrapper {
  display: block;
}
.swiper:not(.swiper-initialized) .swiper-pagination-bullet {
  display: none;
}

.js-slider .swiper:not(.swiper-initialized) {
  overflow: initial;
}
.js-slider .swiper:not(.swiper-initialized) .swiper-wrapper {
  display: flex;
}
.js-slider .swiper:not(.swiper-initialized) .swiper-slide {
  height: auto;
}

.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: 15px;
}

.swiper-button-next.swiper-button-disabled, .swiper-button-prev.swiper-button-disabled {
  opacity: 0;
}

.swiper-button-next,
.swiper-button-prev {
  width: 36px;
}

.swiper-button-prev:after, .swiper-rtl .swiper-button-next:after {
  content: "";
  width: 36px;
  height: 36px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(../img/top/icon_arrow_l.svg);
}

.swiper-button-next:after, .swiper-rtl .swiper-button-prev:after {
  content: "";
  width: 36px;
  height: 36px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(../img/top/icon_arrow_l.svg);
  transform: rotate(180deg);
}

@media (max-width: 768px) {
  .swiper {
    overflow: visible;
  }

  .swiper-slide {
    height: auto;
  }

  .js-slider {
    overflow: hidden;
  }
  .js-slider .slide {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}
[data-accordion] {
  --cssui-animation-timing: 0.3s;
  --cssui-animation-rotate: rotate(-180deg);
  --accordion-panel-padding-y: 1em;
}

[data-accordion-item] {
  width: 100%;
}

[data-accordion-item] > input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

[data-accordion-item] > label {
  cursor: pointer;
}

/* Accordion Item's Label Focus State */
[data-accordion-item] > input:focus + label {
  outline: 2px solid #101010;
  outline: 2px solid highlight;
  outline: 2px solid -webkit-focus-ring-color;
}

/* Hide focus ring if focus comes from a pointer device. */
[data-accordion-item] > input:focus:not(:focus-visible) + label {
  outline: none;
}

[data-accordion-item] > label > svg {
  transition: all var(--cssui-animation-timing) ease-out;
}

[data-accordion-panel] {
  max-height: 0;
  transition: padding var(--cssui-animation-timing) ease;
  visibility: hidden;
  padding-block: 0;
}

[data-accordion-item] > input:checked + label > svg {
  transform: rotate(-180deg);
}

[data-accordion-item] > input:checked ~ [data-accordion-panel] {
  max-height: 100vh;
  visibility: visible;
  padding-block: var(--accordion-panel-padding-y);
}

.no-js img.lazyload {
  display: none;
}

.lazyload,
.lazyloading {
  opacity: 0;
}

.lazyloaded {
  opacity: 1;
  transition: opacity 0.4s;
}

/**
 * どのような構造で配置されていても、左右画面いっぱいに広がるようにする
 */
/**
  全てのフォームコンポーネントの共通機能
  inputに直接付与すること
 */
/**
  input,textarea,select
 */
/**
GMJのフォームの補助テキストは、基本的に「下揃え」
 */
.m-bg_wave {
  position: absolute;
  display: block;
  width: 2404px;
  z-index: 0;
  left: 50%;
  transform: translateX(-50%);
}
.m-bg_wave img {
  object-fit: cover;
  object-position: center;
}

.m-cv_area .m-cv_area_inner {
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 80px;
}
.m-cv_area p {
  font-weight: 400;
  font-size: 22px;
  line-height: 160%;
  text-align: center;
  color: #c9ad5f;
  margin: auto;
}
.m-cv_area .a-cv_btn_wrap {
  max-width: 343px;
  margin: auto;
  margin-top: 40px;
}
@media (min-width: 769px) {
  .m-cv_area .m-cv_area_inner {
    padding-left: 80px;
    padding-right: 80px;
    padding-bottom: 120px;
  }
  .m-cv_area p {
    font-size: 28px;
    line-height: 140%;
  }
  .m-cv_area .a-cv_btn_wrap {
    max-width: 600px;
  }
}

.a-cvBtn_inner {
  display: flex;
  height: 80px;
  font-weight: 400;
  font-size: 20px;
  line-height: 140%;
  color: #fff;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.25);
  position: relative;
}
.a-cvBtn_inner .-marker {
  background: var(--global-color-primary);
  padding: 4px 8px;
  margin-right: 8px;
  display: block;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
}
.a-cvBtn_inner .-icon {
  position: relative;
  width: 24px;
  height: 12px;
  margin-left: 7px;
  background-image: url(../img/common/icon_arrow.svg);
  background-repeat: no-repeat;
  background-size: contain;
}
@media (min-width: 769px) {
  .a-cvBtn_inner {
    font-size: 24px;
    height: 72px;
  }
  .a-cvBtn_inner .-marker {
    font-size: 20px;
    line-height: 140%;
    padding: 1px 12px 2px;
    margin-right: 20px;
  }
  .a-cvBtn_inner .-icon {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 24px;
    margin: auto;
  }
}

.a-cvBtn {
  display: block;
  width: 100%;
  margin: auto 0;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(93.28deg, #ae9553 1.75%, #cfba83 23.2%, #b99b4b 99.83%);
  background: linear-gradient(93.28deg, #ae9553 1.75%, #cfba83 23.2%, #b99b4b 99.83%);
  border: 1px solid #000;
  border-image: var(--global-gradation_gold);
  border-image-slice: 1;
  padding: 3px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
}
.a-cvBtn:hover {
  opacity: 0.7;
}
@media (min-width: 769px) {
  .a-cvBtn {
    padding: 7px;
  }
}

.m-globalHeading {
  text-align: center;
}
.m-globalHeading h2 {
  display: block;
  font-size: 24px;
  font-weight: 400;
  line-height: 140%;
  color: #111111;
}
.-bg_navy .m-globalHeading h2 {
  color: #fff;
}
@media (min-width: 769px) {
  .m-globalHeading h2 {
    font-size: 32px;
  }
}
.m-globalHeading .sub {
  display: inline-block;
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  box-sizing: border-box;
  border: 1px solid #111111;
  padding: 8px;
  margin-bottom: 24px;
}
.-bg_navy .m-globalHeading .sub {
  color: #fff;
  border-color: #fff;
}
@media (min-width: 769px) {
  .m-globalHeading .sub {
    margin-bottom: 12px;
  }
}
.m-globalHeading span {
  font-family: "Montserrat";
  display: block;
  margin-top: 4px;
  color: #7a7159;
  font-weight: 400;
  font-size: 24px;
  line-height: 140%;
}
.m-globalHeading .-line {
  display: block;
  width: 100%;
  max-width: 240px;
  height: 4px;
  margin: 12px auto 0;
  background: linear-gradient(93.28deg, #ae9553 1.75%, #cfba83 23.2%, #b99b4b 99.83%);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  border-radius: 1px;
  overflow: hidden;
}
@media (min-width: 769px) {
  .m-globalHeading .-line {
    max-width: 360px;
  }
}

.sct-about {
  position: relative;
  overflow: hidden;
}
.sct-about .sct_inner {
  padding: 80px 24px 40px;
}
@media (min-width: 769px) {
  .sct-about .sct_inner {
    padding: 120px 80px 0;
  }
}

.o-about_intro {
  font-weight: 400;
  font-size: 22px;
  line-height: 160%;
  color: #c9ad5f;
  text-align: center;
}
@media (min-width: 769px) {
  .o-about_intro {
    font-size: 28px;
    line-height: 140%;
  }
}

.o-about_wrap {
  max-width: 1008px;
  margin: auto;
  margin-top: 40px;
  position: relative;
}
@media (min-width: 769px) {
  .o-about_wrap {
    margin-top: 80px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
}
@media (min-width: 769px) {
  .o-about_wrap .textbox {
    max-width: 480px;
    flex: 1 1 47.619047619%;
    margin-right: 72px;
  }
}
.o-about_wrap .textbox h3 {
  display: block;
  padding: 40px 8px;
  box-sizing: border-box;
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
  text-align: center;
  color: #ffffff;
  border: 1px solid #000;
  border-image: var(--global-gradation_gold);
  border-image-slice: 1;
}
@media (min-width: 769px) {
  .o-about_wrap .textbox h3 {
    font-size: 20px;
  }
}
.o-about_wrap .textbox p {
  font-family: YuGothic, "Yu Gothic", sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 180%;
  color: #ffffff;
  margin-top: 24px;
}
.o-about_wrap picture {
  display: block;
  margin-top: 40px;
}
@media (min-width: 769px) {
  .o-about_wrap picture {
    max-width: 456px;
    flex: 1 1 45.2380952381%;
    margin-top: 0;
  }
}

.o-about_text {
  margin-top: 40px;
  padding: 40px 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 180%;
  color: #ffffff;
  background: #113658;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
}
@media (min-width: 769px) {
  .o-about_text {
    max-width: 1008px;
    margin: auto;
    margin-top: 100px;
    padding: 40px 64px;
    font-size: 16px;
  }
}

.sct-case {
  position: relative;
  overflow: hidden;
}
.sct-case .sct_inner {
  position: relative;
  padding: 86px 24px 0;
}
@media (min-width: 769px) {
  .sct-case .sct_inner {
    padding: 120px 80px 0;
  }
}
.sct-case .sec-description {
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 180%;
  margin-top: 40px;
  color: #fff;
}
.sct-case .note {
  font-family: "YuGothic";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 180%;
  text-align: right;
  color: #ffffff;
  max-width: 1008px;
  margin: auto;
  margin-top: 16px;
}
@media (min-width: 769px) {
  .sct-case .note {
    margin-top: -24px;
  }
}

.o-case_cards {
  max-width: 1008px;
  margin: auto;
  margin-top: 40px;
  position: relative;
}
@media (min-width: 769px) {
  .o-case_cards {
    margin-top: 72px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.m-case_card {
  padding: 8px;
  background: #f1f1f1;
}
.m-case_card + .m-case_card {
  margin-top: 40px;
}
@media (min-width: 769px) {
  .m-case_card {
    flex: 1 1 calc(50% - 24px);
    margin-bottom: 40px;
  }
  .m-case_card:nth-child(even) {
    margin-left: 48px;
  }
  .m-case_card + .m-case_card {
    margin-top: 0;
  }
}

.case_card_inner {
  text-align: center;
  border: 1px solid #111111;
  padding: 32px 15px 15px;
  height: 100%;
}
.case_card_inner h3 {
  position: relative;
  display: block;
  padding: 8px 10px 4px 9px;
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  text-align: center;
  color: #ffffff;
  background: #0b2945;
}
@media (min-width: 769px) {
  .case_card_inner h3 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 8px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 16px;
  }
}
.case_card_inner .tag {
  position: absolute;
  top: -17px;
  left: -8px;
  display: inline-block;
  padding: 3px 8px;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  color: #0b2945;
  background: linear-gradient(93.28deg, #ae9553 1.75%, #cfba83 23.2%, #b99b4b 99.83%);
}
@media (min-width: 769px) {
  .case_card_inner .tag {
    position: relative;
    top: auto;
    left: auto;
    height: 24px;
    margin-right: 8px;
  }
}
@media (min-width: 769px) {
  .case_card_inner .heading {
    text-align: center;
    flex: auto;
  }
}
.case_card_inner .incom {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.case_card_inner .incom_text {
  display: inline-block;
  font-weight: 700;
  font-size: 20px;
  line-height: 150%;
  color: #7a7159;
  padding-bottom: 15px;
}
.case_card_inner .incom_num {
  font-weight: 700;
  font-size: 52px;
  line-height: 150%;
  margin-left: 4px;
  margin-right: 3px;
}

@media (min-width: 769px) {
  .case_card_flex {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 400px;
    margin: auto;
  }
  .case_card_flex .case_card_text,
.case_card_flex .case_card_tag {
    margin: 0 8px;
  }
}

.case_card_text {
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  text-align: center;
  color: #0b2945;
  margin-top: 8px;
}

.case_card_tag {
  display: inline-block;
  border: 1px solid #0b2945;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #0b2945;
  padding: 0 12px;
}

.sct-data {
  position: relative;
  overflow: hidden;
}
.sct-data .sct_inner {
  position: relative;
  padding: 80px 24px;
}
@media (min-width: 769px) {
  .sct-data .sct_inner {
    padding-left: 80px;
    padding-right: 80px;
    padding-top: 120px;
    padding-bottom: 120px;
  }
}

.o-data_list_wrap {
  position: relative;
  margin-top: 56px;
  max-width: 1200px;
}
@media (min-width: 769px) {
  .o-data_list_wrap {
    margin-top: 80px;
    display: flex;
    justify-content: center;
    left: calc(50% + 40px);
    margin-left: -40px;
    margin-right: -40px;
    transform: translateX(-50%);
  }
}

.m-data_list + .m-data_list {
  margin-top: 80px;
}
.m-data_list h3 {
  display: block;
  background: #0060b8;
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
  text-align: center;
  color: #ffffff;
  max-width: 168px;
  margin: auto;
  padding-top: 2px;
  padding-bottom: 4px;
}
.m-data_list picture {
  display: block;
  max-width: 272px;
  margin: auto;
  margin-top: 24px;
}
.m-data_list p {
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #ffffff;
  margin-top: 24px;
}
@media (min-width: 769px) {
  .m-data_list {
    flex: 1 1 calc(33.333% - 40px);
    margin: 0 40px;
  }
  .m-data_list + .m-data_list {
    margin-top: 0;
  }
}

.sct-flow {
  position: relative;
  overflow: hidden;
  background: #f1f1f1;
}
.sct-flow .sct_inner {
  padding: 80px 24px;
}
@media (min-width: 769px) {
  .sct-flow .sct_inner {
    padding-top: 120px;
  }
}

.o-flow_wrap {
  margin-top: 56px;
}
@media (min-width: 769px) {
  .o-flow_wrap {
    max-width: 1120px;
    margin: auto;
    margin-top: 80px;
    display: flex;
    justify-content: center;
  }
}

.m-flow_item {
  position: relative;
}
.m-flow_item + .m-flow_item {
  margin-top: 72px;
}
.m-flow_item:nth-child(2) .m-flow_item_step {
  background: #b9a87b;
}
.m-flow_item:nth-child(3) .m-flow_item_step {
  background: #7a7159;
}
.m-flow_item:not(:last-child)::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: -48px;
  margin: auto;
  display: block;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(../img/top/icon_arrow2.svg);
  transform: rotate(90deg);
}
.m-flow_item picture {
  display: block;
  max-width: 216px;
  margin: auto;
  margin-top: 40px;
  border-radius: 50%;
  overflow: hidden;
}
.m-flow_item h3 {
  display: block;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 140%;
  text-align: center;
  margin-top: 40px;
}
.m-flow_item p {
  font-family: "YuGothic";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 180%;
  margin-top: 24px;
  text-align: center;
}
@media (min-width: 769px) {
  .m-flow_item {
    flex: 1 1 calc(33.33% - 53.3333333333px);
  }
  .m-flow_item + .m-flow_item {
    margin-top: 0;
    margin-left: 80px;
  }
  .m-flow_item:not(:last-child)::after {
    transform: none;
    width: 24px;
    top: 0;
    bottom: 0;
    right: -52px;
    left: auto;
  }
}

.m-flow_item_step {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background: #c9ad5f;
  padding: 4px;
}
.m-flow_item_step span:nth-child(1) {
  display: inline-block;
  padding-bottom: 4px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 100%;
  color: #ffffff;
}
.m-flow_item_step span:nth-child(2) {
  display: inline-block;
  margin-left: 8px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 100%;
  color: #ffffff;
}

.sct-intro {
  position: relative;
  overflow: hidden;
  background: #dfddd6;
}
.sct-intro .sct_inner {
  padding: 56px 24px 80px;
}
@media (min-width: 769px) {
  .sct-intro .sct_inner {
    padding-top: 64px;
    padding-bottom: 64px;
  }
}

.sct-intro_deco {
  position: absolute;
  width: 403.25px;
  height: 216.85px;
  left: -107.32px;
  top: 0;
  background: radial-gradient(50% 50% at 50% 50%, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
  mix-blend-mode: overlay;
  transform: matrix(0.79, 0.61, -0.49, 0.87, 0, 0);
  z-index: 1;
}
@media (min-width: 769px) {
  .sct-intro_deco {
    width: 907.3px;
    height: 487.92px;
    left: calc(50% - 453.65px - 250.75px);
  }
}

.o-intro_wrap {
  position: relative;
  z-index: 10;
  background: #efeeea;
  padding: 8px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.19);
  max-width: 1120px;
  margin: auto;
}
@media (min-width: 769px) {
  .o-intro_wrap {
    padding: 12px;
  }
}

.o-intro_inner {
  background: #ffffff;
  padding: 48px 8px;
  box-sizing: border-box;
  border: 1px solid #000;
  border-image: var(--global-gradation_gold);
  border-image-slice: 1;
}
@media (min-width: 769px) {
  .o-intro_inner {
    padding: 48px 16px;
  }
}
.o-intro_inner > * {
  display: block;
}
.o-intro_inner h2 {
  font-weight: 600;
  font-size: 20px;
  line-height: 140%;
  text-align: center;
  letter-spacing: 0.05em;
}
@media (min-width: 769px) {
  .o-intro_inner h2 {
    font-size: 24px;
    line-height: 140%;
  }
}
.o-intro_inner .a-Logo {
  width: 130px;
  margin: auto;
  margin-top: 16px;
  margin-bottom: 24px;
}
@media (min-width: 769px) {
  .o-intro_inner .a-Logo {
    width: 140px;
    margin-bottom: 16px;
  }
}
.o-intro_inner p {
  max-width: 700px;
  font-weight: 400;
  font-size: 15px;
  line-height: 200%;
  text-indent: -1em;
  padding-left: 1em;
  margin: auto;
  font-size: 20px;
  font-weight: 700;
  color: #7A7159;
}
@media (min-width: 769px) {
  .o-intro_inner p {
    font-size: 20px;
    font-weight: 700;
    color: #7A7159;
  }
}

.sct-job_information {
  position: relative;
  overflow: hidden;
  background: #f1f1f1;
}
.sct-job_information .sct_inner {
  padding: 80px 24px 0;
}
@media (min-width: 769px) {
  .sct-job_information .sct_inner {
    padding: 120px 80px 0;
  }
}
.sct-job_information .sec-description {
  text-align: left;
  font-weight: 400;
  font-size: 16px;
  line-height: 180%;
  margin-top: 40px;
}
@media (min-width: 769px) {
  .sct-job_information .sec-description {
    text-align: center;
  }
}
.sct-job_information .m-cv_area {
  padding-top: 252px;
  margin-top: -217px;
}
@media (min-width: 769px) {
  .sct-job_information .m-cv_area {
    margin-top: -230px;
    padding-top: 178px;
  }
}

.o-job_information_lists {
  position: relative;
  margin-top: 40px;
  left: calc(50% + 24px);
  margin-left: -24px;
  margin-right: -24px;
  transform: translateX(-50%);
}
.o-job_information_lists .swiper-wrapper {
  margin: auto;
}
.o-job_information_lists .note {
  font-family: "YuGothic";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 180%;
  text-align: right;
  color: #ffffff;
  margin-top: 16px;
  padding-right: 24px;
}
@media (min-width: 769px) {
  .o-job_information_lists {
    margin: auto;
    margin-top: 80px;
    max-width: 1280px;
    left: 0;
    transform: none;
  }
  .o-job_information_lists .note {
    margin-top: 18px;
    padding-right: 0;
  }
}

.m-job_information_list .slide {
  max-width: 296px;
  height: 100%;
  margin: auto;
  box-shadow: 2px 2px 16px rgba(164, 169, 180, 0.6);
}
@media (min-width: 769px) {
  .m-job_information_list {
    flex: 1 1 calc(25% - 24px);
  }
  .m-job_information_list + .m-job_information_list {
    margin-left: 32px;
  }
}

.list_inner {
  background: #fff;
}

.job_name {
  display: block;
  padding: 10px;
  font-weight: 700;
  font-size: 18px;
  line-height: 140%;
  text-align: center;
  color: #ffffff;
  background: linear-gradient(93.92deg, #786432 -12.67%, #9a8449 27.55%, #88784e 106.2%);
}

.job_text {
  padding: 24px;
}
.job_text h4 {
  display: block;
  font-weight: 700;
  font-size: 14px;
  line-height: 180%;
  color: #7a7159;
  margin-top: 12px;
  text-align: left;
}
.job_text p {
  font-family: YuGothic, "Yu Gothic", sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 180%;
  margin-top: 8px;
  text-align: left;
}

.incom_tag {
  display: inline-block;
  font-weight: 700;
  font-size: 18px;
  line-height: 100%;
  text-align: center;
  color: #ffffff;
  padding: 4px 12px;
  background: #7a7159;
  margin-top: 16px;
}

.incom_num {
  font-weight: 700;
  font-size: 30px;
  line-height: 150%;
}
.incom_num .-font-s {
  font-size: 18px;
}

.sct-merit {
  position: relative;
  overflow: hidden;
}
.sct-merit .sct_inner {
  padding: 80px 24px;
}
@media (min-width: 769px) {
  .sct-merit .sct_inner {
    padding-top: 120px;
    padding-bottom: 160px;
    padding-left: 0;
    padding-right: 0;
  }
}

.m-merit_content {
  margin-top: 40px;
}
.m-merit_content + .m-merit_content {
  margin-top: 56px;
}
@media (min-width: 769px) {
  .m-merit_content {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex-flow: row-reverse;
    max-width: 1440px;
    margin: 0 auto;
    margin-top: 80px;
  }
  .m-merit_content.-reverse {
    flex-flow: row;
  }
  .m-merit_content.-reverse .textbox {
    padding-left: 120px;
    padding-right: 80px;
  }
  .m-merit_content + .m-merit_content {
    margin-top: 80px;
  }
}
.m-merit_content .sub {
  font-family: "Montserrat";
  font-style: normal;
  color: #7a7159;
}
.m-merit_content .sub .font-s {
  display: inline-block;
  font-size: 24px;
  font-weight: 400;
  line-height: 150%;
  margin-right: 12px;
  padding-bottom: 10px;
}
.m-merit_content .sub .num {
  display: inline-block;
  font-size: 40px;
  font-weight: 400;
  line-height: 150%;
}
.m-merit_content .line {
  display: block;
  width: 140px;
  height: 1px;
  background: #7a7159;
}
.m-merit_content h3 {
  display: inline-block;
  font-weight: 700;
  font-size: 24px;
  line-height: 150%;
  margin-top: 32px;
}
.m-merit_content .text {
  font-family: YuGothic, "Yu Gothic", sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 180%;
  text-align: left;
  margin-top: 24px;
}
.m-merit_content .textbox {
  padding-bottom: 40px;
}
@media (min-width: 769px) {
  .m-merit_content .textbox {
    box-sizing: content-box;
    width: 38.8888888889%;
    padding-top: 80px;
    padding-left: 80px;
    padding-right: 120px;
  }
}
.m-merit_content .imgbox {
  display: block;
  position: relative;
}
@media (max-width: 767px) {
  .m-merit_content .imgbox {
    left: calc(50% + 24px);
    margin-left: -24px;
    margin-right: -24px;
    transform: translateX(-50%);
  }
}
@media (min-width: 769px) {
  .m-merit_content .imgbox {
    width: 44.4444444444%;
    height: 426px;
  }
  .m-merit_content .imgbox img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.sct-success {
  position: relative;
  overflow: hidden;
}
.sct-success .sct_inner {
  padding: 80px 24px;
}
@media (min-width: 769px) {
  .sct-success .sct_inner {
    padding: 120px 80px;
  }
}

.o-success_lists {
  max-width: 1280px;
  margin: auto;
  margin-top: 56px;
  position: relative;
}
@media (min-width: 769px) {
  .o-success_lists {
    margin-top: 80px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.m-success_list {
  position: relative;
  background: #fff;
  box-shadow: 2px 2px 16px rgba(164, 169, 180, 0.6);
  border: 1px solid #000;
  border-image: var(--global-gradation_gold2);
  border-image-slice: 1;
  max-width: 400px;
  margin: 0 auto;
}
.m-success_list + .m-success_list {
  margin-top: 40px;
}
@media (min-width: 769px) {
  .m-success_list {
    flex: 1 1 calc(33.333% - 26.6666666667px);
  }
  .m-success_list + .m-success_list {
    margin-top: 0;
    margin-left: 40px;
  }
}
.m-success_list::before {
  content: "";
  position: absolute;
  top: 0;
  display: block;
  width: 100%;
  height: 8px;
  background: #c9ad5f;
}
.m-success_list .tag {
  position: absolute;
  top: 0;
  right: 0;
  display: inline-block;
  width: 120px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 100%;
  color: #ffffff;
  background: #c9ad5f;
  padding: 5px 12px 4px;
}
.m-success_list .tag .num {
  display: inline-block;
  font-weight: 400;
  font-size: 32px;
  line-height: 100%;
  margin-left: 12px;
}

.m-success_list_inner {
  padding: 48px 24px 40px;
}
.m-success_list_inner h3 {
  display: block;
  font-weight: 700;
  font-size: 20px;
  line-height: 140%;
  margin-top: 24px;
}

.m-success_list_text {
  font-family: YuGothic, "Yu Gothic", sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 180%;
  margin-top: 8px;
}

.m-prof {
  display: flex;
}
.m-prof picture {
  display: block;
  width: 88px;
}
.m-prof .textbox {
  flex: auto;
  position: relative;
  margin-left: 16px;
}
.m-prof .textbox p {
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;
  color: #7a7159;
  text-align: left;
  margin-top: 19px;
}
.m-prof .textbox p + p {
  margin-top: 16px;
}
.m-prof .textbox .name {
  font-size: 14px;
  line-height: 180%;
}
.m-prof .textbox .prof_tag {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  background: #7a7159;
  font-weight: 700;
  font-size: 14px;
  line-height: 100%;
  color: #ffffff;
  padding: 1px 2px 2px;
}

.m-success_list_point {
  position: relative;
  padding-top: 20px;
  padding-bottom: 16px;
  margin-top: 25px;
  border-top: 2px solid #c9ad5f;
  border-bottom: 2px solid #c9ad5f;
}
.m-success_list_point span {
  position: absolute;
  top: -17px;
  left: 0;
  display: inline-block;
  background: #fff;
  font-weight: 700;
  font-size: 20px;
  line-height: 180%;
  color: #c9ad5f;
  padding-right: 8px;
}
.m-success_list_point p {
  font-family: YuGothic, "Yu Gothic", sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 180%;
}

.sct-main {
  position: relative;
  background: #0B2945;
}
@media (min-width: 769px) {
  .sct-main {
    height: 680px;
  }
}

.mv {
  display: block;
  height: auto;
  box-sizing: border-box;
  padding-bottom: 24px;
}
.mv img {
  display: block;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
@media (min-width: 769px) {
  .mv {
    padding-bottom: 0;
    height: 100%;
  }
}

.o-mv_wrap {
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 32px;
  padding-left: 14px;
  width: 100%;
}
@media (min-width: 769px) {
  .o-mv_wrap {
    max-width: 1440px;
    margin: auto;
    right: 0;
    padding-top: 104px;
    padding-left: 96px;
  }
}

.mv_label {
  width: 193px;
}
@media (min-width: 769px) {
  .mv_label {
    width: 757px;
  }
}

.subttl {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 130%;
  margin-top: 8px;
}
@media (min-width: 769px) {
  .subttl {
    font-size: 24px;
    line-height: 140%;
    margin-top: 16px;
  }
}

.mainttl {
  position: relative;
  display: block;
  margin-top: 4px;
  font-weight: 400;
  font-size: 26px;
  line-height: 130%;
  letter-spacing: -0.05em;
  color: #ffffff;
  z-index: 10;
}
.mainttl span {
  font-size: 58px;
  line-height: 130%;
}
.mainttl::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 160px;
  height: 120px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(../img/top/image_map_hiroshima.svg);
  z-index: -1;
  opacity: 1;
}
@media (min-width: 769px) {
  .mainttl {
    font-size: 48px;
    line-height: 140%;
    margin-top: 32px;
  }
  .mainttl span {
    font-size: 80px;
    line-height: 140%;
  }
  .mainttl::before {
    top: -24px;
    width: 209px;
    height: 197px;
  }
}