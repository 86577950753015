@use 'css/abstracts' as *;
.sct-about {
	position: relative;
	overflow: hidden;

	.sct_inner {
		padding: 80px 24px 40px;
		@media (min-width: 769px) {
			padding: 120px 80px 0;
		}
	}
}

.o-about_intro {
	font-weight: 400;
	font-size: 22px;
	line-height: 160%;
	color: #c9ad5f;
	text-align: center;
	@media (min-width: 769px) {
		font-size: 28px;
		line-height: 140%;
	}
}

.o-about_wrap {
	max-width: 1008px;
	margin: auto;
	margin-top: 40px;
	position: relative;
	@media (min-width: 769px) {
		margin-top: 80px;
		display: flex;
		align-items: flex-start;
		justify-content: center;
	}
	.textbox {
		@media (min-width: 769px) {
			max-width: 480px;
			flex: 1 1 calc(480 / 1008 * 100%);
			margin-right: 72px;
		}
		h3 {
			display: block;
			padding: 40px 8px;
			box-sizing: border-box;
			font-weight: 400;
			font-size: 18px;
			line-height: 140%;
			text-align: center;
			color: #ffffff;
			border: 1px solid #000;
			border-image: var(--global-gradation_gold);
			border-image-slice: 1;
			@media (min-width: 769px) {
				font-size: 20px;
			}
		}
		p {
			font-family: YuGothic, 'Yu Gothic', sans-serif;
			font-weight: 500;
			font-size: 14px;
			line-height: 180%;
			color: #ffffff;
			margin-top: 24px;
		}
	}
	picture {
		display: block;
		margin-top: 40px;
		@media (min-width: 769px) {
			max-width: 456px;
			flex: 1 1 calc(456 / 1008 * 100%);
			margin-top: 0;
		}
	}
}

.o-about_text {
	margin-top: 40px;
	padding: 40px 16px;
	font-weight: 400;
	font-size: 14px;
	line-height: 180%;
	color: #ffffff;
	background: #113658;
	box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
	@media (min-width: 769px) {
		max-width: 1008px;
		margin: auto;
		margin-top: 100px;
		padding: 40px 64px;
		font-size: 16px;
		}
}
