@use 'css/abstracts' as *;

.m-bg_wave{
	position: absolute;
	display: block;
	width: 2404px;
	z-index: 0;
	left: calc(50%);
		transform: translateX(-50%);
	img{
		object-fit: cover;
		object-position: center;
	}
}


.m-cv_area {
	.m-cv_area_inner {
		padding-left: 16px;
		padding-right: 16px;
		padding-bottom: 80px;
	}
	p {
		font-weight: 400;
		font-size: 22px;
		line-height: 160%;
		text-align: center;
		color: #c9ad5f;
		margin: auto;
	}
	.a-cv_btn_wrap {
		max-width: 343px;
		margin: auto;
		margin-top: 40px;
	}
	@media (min-width: 769px) {
		.m-cv_area_inner {
			padding-left: 80px;
			padding-right: 80px;
			padding-bottom: 120px;
		}
		p {
			font-size: 28px;
			line-height: 140%;
		}
		.a-cv_btn_wrap {
			max-width: 600px;
		}
	}
}

.a-cvBtn_inner {
	display: flex;
	height: 80px;
	font-weight: 400;
	font-size: 20px;
	line-height: 140%;
	color: #fff;
	justify-content: center;
	align-items: center;
	background: rgba(0, 0, 0, 0.25);
	position: relative;
	.-marker {
		background: var(--global-color-primary);
		padding: 4px 8px;
		margin-right: 8px;
		display: block;
		font-weight: 400;
		font-size: 16px;
		line-height: 120%;
	}
	.-icon {
		position: relative;
		width: 24px;
		height: 12px;
		margin-left: 7px;
		background-image: url(../img/common/icon_arrow.svg);
		background-repeat: no-repeat;
		background-size: contain;
	}
	@media (min-width: 769px) {
		font-size: 24px;
		height: 72px;
		.-marker {
			font-size: 20px;
			line-height: 140%;
			padding: 1px 12px 2px;
			margin-right: 20px;
		}
		.-icon {
			position: absolute;
			top: 0;bottom: 0;
			right: 24px;
			margin: auto;
		}
	}
}
.a-cvBtn {
	display: block;
	width: 100%;
	margin: auto 0;
	background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
		linear-gradient(93.28deg, #ae9553 1.75%, #cfba83 23.2%, #b99b4b 99.83%);
	background: linear-gradient(
		93.28deg,
		#ae9553 1.75%,
		#cfba83 23.2%,
		#b99b4b 99.83%
	);
	border: 1px solid #000;
	border-image: var(--global-gradation_gold);
	border-image-slice: 1;
	padding: 3px;
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
	&:hover{
		opacity: .7;
	}
	@media (min-width: 769px) {
		padding: 7px;
	}
}

.m-globalHeading {
	text-align: center;

	h2 {
		display: block;
		font-size: 24px;
		font-weight: 400;
		line-height: 140%;
		color: #111111;
		.-bg_navy & {
			color: #fff;
		}
		@media (min-width: 769px) {
			font-size: 32px;
		}
	}
	.sub {
		display: inline-block;
		font-weight: 400;
		font-size: 18px;
		line-height: 150%;
		box-sizing: border-box;
		border: 1px solid #111111;
		padding: 8px;
		margin-bottom: 24px;
		.-bg_navy & {
			color: #fff;
			border-color: #fff;
		}
		@media (min-width: 769px) {
			margin-bottom: 12px;
		}
	}
	span {
		font-family: 'Montserrat';
		display: block;
		margin-top: 4px;
		color: #7a7159;
		font-weight: 400;
		font-size: 24px;
		line-height: 140%;
	}
	.-line {
		display: block;
		width: 100%;
		max-width: 240px;
		height: 4px;
		margin: 12px auto 0;
		background: linear-gradient(
			93.28deg,
			#ae9553 1.75%,
			#cfba83 23.2%,
			#b99b4b 99.83%
		);
		box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
		border-radius: 1px;
		overflow: hidden;
		@media (min-width: 769px) {
			max-width: 360px;
		}
	}
}
